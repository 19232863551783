import React, {useEffect, useState} from 'react';
import {CheckoutControllerProps} from '../../../types/checkoutApp.types';
import {useEnvironment, useExperiments, WidgetProps} from '@wix/yoshi-flow-editor';
import {ONE_COLUMN_VIEW_WIDTH_THRESHOLD} from '../constants';
import {SPECS} from '../../../common/constants';

type VoidReturn<T> = T extends (...args: infer P) => unknown ? (...args: P) => void : T;
type VoidMethodsDeep<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K] extends Record<string, unknown> ? VoidMethodsDeep<T[K]> : VoidReturn<T[K]>;
};
export type UseControllerPropsResult = WidgetProps<VoidMethodsDeep<CheckoutControllerProps>>;

const Context = React.createContext({} as WidgetProps<CheckoutControllerProps>);

export function useControllerProps(): UseControllerPropsResult {
  return React.useContext(Context);
}

export function ControllerContext({
  value,
  children,
}: {
  value: WidgetProps<CheckoutControllerProps>;
  children: React.ReactNode;
}) {
  const {isMobile} = useEnvironment();
  const {experiments} = useExperiments();
  const shouldFixSSRInMobileFlicker = experiments.enabled(SPECS.shouldFixSSRInMobileFlicker);
  const [isOneColumnView, setIsOneColumnView] = useState<boolean>(shouldFixSSRInMobileFlicker && isMobile);
  useEffect(
    () => {
      if (shouldFixSSRInMobileFlicker && isMobile) {
        return;
      }
      const handleResize = () => {
        setIsOneColumnView(window?.innerWidth <= ONE_COLUMN_VIEW_WIDTH_THRESHOLD);
      };
      handleResize();
      window?.addEventListener('resize', handleResize);
      return () => {
        window?.removeEventListener('resize', handleResize);
      };
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ []
  );
  return <Context.Provider value={{...value, isOneColumnView}}>{children}</Context.Provider>;
}
